<section class="pb-5" style="background-color: #000" id="work" class="offset">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="main-head">
          <h1>Work Process</h1>
          <p style="font-size: 16px;">Being living in the 21st century, BIM is poised to be a significant invention in
            the<br>
            architectural domain. We, through the usage of REVIT are trying to supply oxygen<br> to this marvelous
            innovation.Along with using REVIT as a tool,<br> we also using several applications which enable are enabled
            through artificial intelligence. <br> The app is capable of calculating quantities from a sketch plan, to
            creating 3D views clubbed with services. </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid mt-0 ">
    <div class="row d-flex justify-content-center nav nav-tabs">
      <div class="col-lg-2 col-sm-6">
        <div class="top-head  pb-4" (click)="tabClicked(1)">
          <img class="tabimg" src="assets/images/Discussion1.png">
          <span [ngStyle]="{color: getColor1()}">INITIAL EVALUATION AND DIALOGUE WITH CLIENT</span>
        </div>
      </div>
      <!-- <div class="col-lg-2">
          <div class="top-head">
            <img class="tabimg" src="assets/images/gavel.png">
            <span>JURISDICTION</span>
          </div>
        </div> -->
      <div class="col-lg-2 col-sm-6">
        <div class="top-head pb-4" (click)="tabClicked(2)">
          <ng-container *ngIf="selectedTab<2; else other2">
            <img class="tabimg" width="64" src="assets/images/think.png">
          </ng-container>
          <ng-template #other2>
            <img class="tabimg"  width="64" src="assets/images/think1.png">
          </ng-template>
          
          
          <span [ngStyle]="{color: getColor2()}">BRAINSTORMING & PEER REVIEW</span>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6">
        <div class="top-head pb-4" (click)="tabClicked(3)">
          <img class="tabimg" *ngIf="selectedTab<3; else other3" src="assets/images/design.png">
          <ng-template #other3>
            <img class="tabimg"  width="64" src="assets/images/design1.png">
          </ng-template>
          <span [ngStyle]="{color: getColor3()}">CONCEPT DESIGN</span>
        </div>
      </div>
      <div class="col-lg-2 col-sm-6">
        <div class="top-head pb-4" (click)="tabClicked(4)">
          <img class="tabimg" *ngIf="selectedTab<4; else other4" src="assets/images/documenta.png">
          <ng-template #other4>
            <img class="tabimg" src="assets/images/documenta1.png">
          </ng-template>
          <span [ngStyle]="{color: getColor4()}">DESIGN DEVELOPMENT & GFC DOCUMENTATION</span>
        </div>
      </div>

    </div>
    <div class="container hideonmobile">
      <div class="row stepdiv">
        <div class="stepnumber d-inline-flex" [ngStyle]="{color: getColor1()}">1</div>
        <div class="step d-inline-flex" [ngStyle]="{color: getColor2()}">
          <hr [style.border-color]="getColor2()">
        </div>
        <div class="stepnumber d-inline-flex" [ngStyle]="{color: getColor2()}">2</div>
        <div class="step d-inline-flex" [ngStyle]="{color: getColor3()}">
          <hr [style.border-color]="getColor3()">
        </div>
        <div class="stepnumber d-inline-flex" [ngStyle]="{color: getColor3()}">3</div>
        <div class="step d-inline-flex" [ngStyle]="{color: getColor4()}">
          <hr [style.border-color]="getColor4()">
        </div>
        <div class="stepnumber d-inline-flex" [ngStyle]="{color: getColor4()}">4</div>
        <!-- <div class="step d-inline-flex"><hr></div>
          <div class="stepnumber d-inline-flex">5</div> -->
      </div>
    </div>
  </div>
  <div class="col-sm-12 pt-5">
    <div class="container" *ngIf="selectedTab == 1">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <!-- <img class="img-fluid" src="assets/images/work_process/1.jpg" alt="img"> -->
          <p>We commence work on our project by undertaking a detailed discussion with our respective client to gauge
            his/her requirements and aspirations. It is imperative for us to understand our client’s budget, time frame,
            design mix and any other parameters which have a direct/indirect impact on the design. </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>The information catered is categorised as ‘The Brief’. Without a holistic view of the plot available to us,
            to proceed with only building plans creates planning hazards at a later stage. Concept for unit provides the
            basic baby step for building plan, which leads to the master plan.</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>Our process are well defined. We set up a
            client meet for product briefing, terms of engagement, identfying SPOC from both ends, finalising contract
            terms and periodic reviews with client. </p>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="selectedTab == 2">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <!-- <img class="img-fluid" src="assets/images/work_process/brainstorm.jpg" alt="img"> -->
          <p>
            Having understood the client's introductory brief, the team carries out its own appraisal work to explore
            whether the client's demands (often couched in terms of area and use-type) can be accommodated on a given
            site. This is a process of strategic assessment of development possibilities, often with a preferred
            direction selected. Much of this work will be diagrammatic and with numerical inferences, with architectural
            design taking a back seat.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>  This is followed by a peer review having engaged in a group of four architects sharing their respective
            observations before commencing concept design. At the time of presentation, Gridlines ensures that a
            complete preview of the project is showcased. In order for that to be done, all presentations are created
            with interior and exterior renderings along with massing studies.</p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>  This allows the client to get a better
            overview of the project. Our design processes are well defined. Post product briefing and initial
            discussions, we move into concept finalisations. At this stage the maximum deliberation is done as all
            issues such as cost, layout, look and feel etc need to be detailed out. </p>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="selectedTab == 3">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <p>Implication of an idea or range of ideas, a development approach and a guiding concept leads to meticulous
            design and building plan. </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>Concept design is seldom a 'Eureka' moment where a single idea resolves
            everything. It resolves the issue of 'what' and 'how much' and begins to set the stage for understanding 'how'. </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <!-- <img class="img-fluid" src="assets/images/work_process/concept_0.png" alt="img"> -->
          <p>
            The
            next progress is design documentation in which frozen concepts are developed for better understanding.
          </p>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="selectedTab == 4">
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <!-- <img class="img-fluid" src="assets/images/work_process/developme.jpg" alt="img"> -->
          <p>
            The architectural design of a project is developed and defined in detail, sufficient to illustrate via
            plans, sections, elevations and 3D imagery, the overall form and fabric of the project and its detailed
            layouts, spatial arrangements, facades, overall appearance and range of construction materials and finishes.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p>
            Construction documentation encompasses the preparation of drawings and specifications that set forth the
            detailed requirements for the construction of a building project. We ensure a systematic review process to
            ensure that all discipline packages (e.g., structural, mechanical, electrical, lighting, audiovisual,
            interiors, etc.) are coordinated and properly integrated.
          </p>
        </div>
        <div class="col-md-4 col-sm-12">
          <p> Schematics are then undertaken and these could be
            considered as pre_GFC drawings. Once approval from all stakeholders is confirmed and services are overlayed
            on architectural plans, we proceed to issuing construction drawings. </p>
        </div>
      </div>
    </div>
  </div>
</section>