import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  private fragment: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => { 
      console.log('fragment', fragment);
      this.fragment = fragment;
      let e = document.getElementById(this.fragment);
      if(e){
        e.scrollIntoView({ behavior: "smooth", block: "start" });
      } 
      
    });
  }
  navMenu=[
    {
      id:'home',
      name:'Home'
    },
    {
      id:'about',
      name:'About'
    },
    {
      id:'services',
      name:'Services'
    },
    {
      id:'projects',
      name:'Our Work'
    },
    {
      id:'contact',
      name:'Contact'
    }]
    scroll(el) {
   
      document.getElementById(el).scrollIntoView({ behavior: "smooth", block: "start" });
    
  
    }

    ngAfterViewInit(): void {
      try {
        setTimeout(function(){
          // document.querySelector('#' + this.fragment).scrollIntoView();
          // console.log('this.fragment',this.fragment);
          // document.getElementById(this.fragment).scrollIntoView({ behavior: "smooth", block: "start" });
        },1000)
        
      } catch (e) { }
    }
}
