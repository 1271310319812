import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { WorkProcessComponent } from './work-process/work-process.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ProjectsComponent } from './projects/projects.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {ContactService } from '../app/contact.service';
import { AgmCoreModule} from '@agm/core'
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks, IntersectionObserverHooks, Attributes } from 'ng-lazyload-image'; // <-- include ScrollHooks
// import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  setup(attributes: Attributes) { 
    super.setup(attributes);
    // This will overwride all offset to 100px
    attributes.offset = 100;
   
    // This will set the offset to 100px if no offset is given
    // attributes.offset = attributes.offset || 100;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    AboutComponent,
    OurServicesComponent,
    WorkProcessComponent,
    ContactComponent,
    HomeComponent,
    FooterComponent,
    ProjectsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LazyLoadImageModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDL3V0NzVX2NMqyhJS2BeOU8F6s2wm6njw'
    })
    
  ],
  providers: [ContactService,{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }],
  bootstrap: [AppComponent]
})
export class AppModule { }
