import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  navMenu=[
    {
      id:'home',
      name:'Home'
    },
    {
      id:'about',
      name:'About'
    },
    {
      id:'services',
      name:'Services'
    },
    {
      id:'contact',
      name:'Contact'
    }]
    scroll(el) {
   
      document.getElementById(el).scrollIntoView({ behavior: "smooth", block: "start" });
    }
}
