<div id="home" class="carousel slide" data-ride="carousel">
    
    <!-- The slideshow --> 
    <div class="carousel-inner">
      <!-- <div class="carousel-item active">
        <img src="assets/images/first_slider.jpg" alt="Img">
      </div> -->
      <div class="carousel-item active">
        <img src="assets/images/Banner_1_0.jpg" alt="Img">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_2_0.jpg" alt="Img">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_3.jpg" alt="Img">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_4.jpg" alt="New York">
      </div>
    </div>
    <a class="carousel-control-prev" role="button" data-target="#home" data-slide="prev">
    <img src="assets/images/more-grey-prev.png">
    </a>
    <a class="carousel-control-next" role="button" data-target="#home" data-slide="next">
      <img src="assets/images/more-grey-next.png">
    </a>
  </div>
