<section id="contact" class="offset">
    <div class="container-fluid row-gap">
        <div class="row">
            <div class="col-12">
                <div class="main-head">
                    <h1>HOW TO FIND US</h1>
                    <p class="sub-title">Get in touch with us or type in your message with contact details below
                        and<br><br>
                        we'll get back to you!</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid row-gap row-bottom pl-lg-5 pr-lg-5">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-5 mt-lg-3 col-md-12 col-sm-12">
                <div class="content">
                    <h5>Address</h5>
                    <p>Shri Sai Plumbing Services,</p>
                    <p>Airport road,Vithalwadi,Shirdi</p>
                    <p>Tal:Rahata,Dist:A.nagar</p>
                    <p>Maharashtra,,Shirdi:423109</p>
                    <h5>Call Us</h5>
                    <p> <a class="phone" href="tel:+9850726961">+ 91 9850726961</a></p>
                    <h5>Email</h5>
                    <p><a class="mail-us" href="mailto:mhaskemachindra60@gmail.com">mhaskemachindra60@gmail.com</a></p>
                </div>
            </div>
            <!-- <div class="col-lg-7 col-md-12 col-sm-12">
                <p style="color: black;" *ngIf="message">Thank you, your message has been submitted to us.</p>
                <a href="#" *ngIf="showLink" (click)="gotoForm()" class="link">Go back to the form</a>
                <form [formGroup]="contactForm" *ngIf="formShow">
                    <div class="row">
                        <div class="col-lg-6">
                            <input type="text" class="form-control" placeholder="Name" name="name" formControlName="name"
                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors"  class="invalid-feedback">
                                <div *ngIf="f.name.errors.required"> Name field is required.</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <input type="email" class="form-control" placeholder="Email" name="email" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required"> Email field is required.</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">

                            <input type="text" class="form-control" placeholder="Subject" name="subject" formControlName="subject"
                                [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                            <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                <div *ngIf="f.subject.errors.required"> Subject field is required.</div>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <input type="number" class="form-control" placeholder="Contact Phone"
                                formControlName="contactNo" name="contact"
                                [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }">
                            <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                                <div *ngIf="f.contactNo.errors.required"> Contact field is required.</div>
                            </div>

                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <textarea class="form-control" cols="5" rows="5" name="message" formControlName="message" [ngClass]="{'is-invalid': submitted && f.message.errors }">Message
                                
                            </textarea>
                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                    <div *ngIf="f.message.errors.required"> Message field is required.</div>
                                </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <button (click)="submit()" [disabled]='!contactForm.valid'>submit<img src="assets/images/more-grey-next.png"></button>
                        </div>
                    </div>
                    <iframe *ngIf="loader==true" src="https://giphy.com/embed/3oEjI6SIIHBdRxXI40"  width="80" height="80" frameBorder="0" class="giphy-embed ml-auto" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/mashable-3oEjI6SIIHBdRxXI40"></a></p>
                </form>
            </div> -->
            <div class="col-lg-7 col-md-12 col-sm-12">
               <agm-map [latitude]="latitude" [longitude]="longitude"></agm-map> 
            </div>
            
            
        </div>
    </div>
</section>