<section id='projects' class="offset">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-12">
                <div class="main-head">
                    <h1>Our Work</h1>
                    <p style="font-size: 16px;">
                        Our methods allow us to identify and connect the best
                         solution to customer. <br>
                        We almost serve more than 200+ customer all over the Maharashtra ,they satisfied with our
                        work done. <br>
                    </p>
                </div>
            </div>
            <div class="container">
                <div class="row row-eq-height projectImg">
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image5.jpeg" class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image6.jpeg"  style="max-height: 160px;width: 100%;" class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image7.jpeg"  style="min-height: 160px;width: 100%;" class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image8.jpeg"  style="max-height: 160px;width: 100%;" class="img-fluid" alt="img">
                    </div>

                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image9.jpeg"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image2.jpeg"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image3.jpeg" style="max-height: 160px;width: 100%;"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image4.jpeg"  class="img-fluid" alt="img">
                    </div>

                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image1.jpeg"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image10.jpeg"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image11.jpeg"  class="img-fluid" alt="img">
                    </div>
                    <div class="col-md-3 col-sm-12 pb-4">
                        <img src="assets/images/projects/image12.jpeg" class="img-fluid" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="our-video">
                    <video width="400"  height="100" controls>
                        <source src="assets/images/videoOne.mp4" type="video/mp4">
                        <source src="assets/images/videoOne.mp4" type="video/ogg">
                        Your browser does not support HTML5 video.
                      </video>
                </div>
            </div>
            <div class="col-md-6">
                <div class="our-video">
                    <video width="550" controls>
                        <source src="assets/images/videoTwo.mp4" type="video/mp4">
                        <source src="assets/images/videoTwo.mp4" type="video/ogg">
                        Your browser does not support HTML5 video.
                      </video>
                </div>
            </div>
        </div>
    </div>
    </section>