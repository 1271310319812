<section id="services" class="offset pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="main-head">
          <h1>Our Services</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="tab-head">
            <h6>TAP/PIPE FITTING</h6>
            <img src="assets/images/tapfitting.jpg" class="tab-img">
            <p>Our range of services include Kitchen Sink Fitting Service, 
              Pipe Fitting Service, Tap Fitting Service, Shower Fitting Service, 
              Bathroom Sink Fitting Service and Plumbers.
              We have dedicated team who will do your tap and pipe fitting 
            problems.</p>
        </div>
    </div>
    <div class="col-md-4">
        <div class="tab-head">
            <h6>WATER LEAKAGES</h6>
            <img src="assets/images/waterLeakage.jpg" class="tab-img">
            <p>
            We provide service for water leakage from tap,pipe,
            types of household leaks hidden underground, behind walls or 
            beneath floors etc.We have dedicated team who will solve your water 
            leakage problems.
            </p>
        </div>
    </div>
    <div class="col-md-4">
        <div class="tab-head">
            <h6>INSTALLATION SERVICES</h6>
            <img src="assets/images/installation.jpeg" class="tab-img">
            <p>
              We Installs, maintains, and repairs pipes and fixtures associated with heating, 
              cooling, water distribution, 
              and sanitation systems in residential and commercial structures. Fixes domestic appliances, such as dishwashers and gas cookers. 
              Inspects drainage and other plumbing systems.
            </p>
        </div>
    </div>

      
    </div>
  </div>
</section>