import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactService } from '../contact.service';
import {} from 'googlemaps';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {

  constructor(private fb:FormBuilder,private Validation:ContactService,private http:HttpClient) { }

  ngOnInit(): void {
  }
  loader:boolean;
  latitude = 51.678418
  longitude = 7.809007
  submitted = false;
  message=false;
  formShow=true;
  showLink=false;
  contactForm= this.fb.group({
    name:['',Validators.required],
    email:['',[Validators.required,Validators.email]],
    subject:['',Validators.required],
    contactNo:['',Validators.required],
    message:['',Validators.required]
  })
   get f(){
    return this.contactForm.controls;
  }
  submit(){
    this.http.get("https://script.google.com/macros/s/AKfycbyi6vdAeTsdCYy0fI_WYVXZovc8TO2dm_xVuc1p0-dUzkhInw-VrxjO/exec",this.contactForm.value).subscribe((res:any)=>{
    console.log("data from res",res);
    this.submitted=true;
    this.message=true;
    this.contactForm.reset(); 
    })
  
}
}