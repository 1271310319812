import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-process',
  templateUrl: './work-process.component.html',
  styleUrls: ['./work-process.component.css']
})
export class WorkProcessComponent implements OnInit {

  selectedTab:any = 1;
  img2:boolean = false;
  img3:boolean = false;
  img4:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  tabClicked(selected){
    this.selectedTab = selected;
    if(selected == 2) {
      this.img2 == true;
    }
  }

  getColor1() {
    if(this.selectedTab >= 1){
      return '#E7BD6F'
    }
  }

  getColor2() {
    if(this.selectedTab >= 2){
      this.img2 == true;
      return '#E7BD6F'
    }
    
  }

  getColor3() {
    if(this.selectedTab >= 3){
      this.img3 == true;
      return '#E7BD6F'
    }
    
  }
  getColor4() {
    if(this.selectedTab >= 4){
      this.img4 == true;
      return '#E7BD6F'
    }
    
  }

}
