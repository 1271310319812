<nav class="navbar navbar-expand-md navbar-dark bg-dark">
    <!-- <a class="navbar-brand" href="#"><img src="assets/images/Gridlines logo_1.png"></a> -->
    <a class="navbar-brand" href="#home">SHRI SAI PLUMBING SERVICE</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="icon-bar top-bar" style="margin-top: 5px;"></span>
	<span class="icon-bar middle-bar"></span>
	<span class="icon-bar bottom-bar" style="margin-bottom: 5px;"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto" id="top-menu">
        <!-- <li class="nav-item" *ngFor="let obj of navMenu">
          <a class="nav-link"  (click)="scroll(obj.id)">{{obj.name}}</a>
        </li> -->
        <li class="nav-item" (click)="scroll(obj.id)" *ngFor="let obj of navMenu">
                    <!-- <a class="nav-link"   (click)="scroll(obj.id)">{{obj.name}}</a> -->
                  <a class="nav-link" [routerLink]="['/']" data-toggle="collapse" data-target=".navbar-collapse.show" fragment="{{obj.id}}">{{obj.name}}</a>
                   </li>
      </ul>
    </div>  
  </nav>
  <!-- <div id="demo" class="carousel slide" data-ride="carousel">
  
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="assets/images/first_slider.jpg" alt="Los Angeles">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_1_0.jpg" alt="Chicago">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_2_0.jpg" alt="New York">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_3.jpg" alt="New York">
      </div>
      <div class="carousel-item">
        <img src="assets/images/Banner_4.jpg" alt="New York">
      </div>
    </div>
    <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <img src="assets/images/more-grey-prev.png">
    </a>
    <a class="carousel-control-next" href="#demo" data-slide="next">
      <img src="assets/images/more-grey-next.png">
    </a>
  </div> -->
