<section id="about" class="offset">
<div class="container">
    <div class="row d-flex justify-content-center">
        <div class="col-lg-12 col-md-12 col-sm-12 main-head">
            <h1 class="mt-2 heading" style="color: var(--main-txt-color);letter-spacing:8px;font-weight:700;">ABOUT US</h1>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <p>
                <b style="font-size: 20px;">SHRI SAI PLUMBING SERVICE</b> 
                is Best in class providing service in the field of plumbing.
                We have over 20 years of experince delivering best 
                qaulity services to customer.We have dedicated team to catter all your needs.
                We provides services such as repairing pipes, tanks, and water fixtures, unclogging toilets and drains, 
                and handling various residential plumbing requirements.
                Our services vary from fixing to new fittings,
                cleaning and other things that come under the expertise of plumber.
                Any kind of obstruction in the household such clogging toilets,
                leakage in pipe may lead waste your times.So we are there at service
                to serve your needs and fix all the issues.  
            </p>
        </div>
    </div>
</div>
</section>